import OmComponent from './OmComponent';
import { DesktopBorder, DesktopShadow, MobileBorder, MobileShadow } from '../coloring';

export default class OmCustomHTML extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.border.color':
        return this.createColorInstance(DesktopBorder);
      case 'desktop.shadow.color':
        return this.createColorInstance(DesktopShadow);
      case 'mobile.border.color':
        return this.createColorInstance(MobileBorder);
      case 'mobile.shadow.color':
        return this.createColorInstance(MobileShadow);
      default:
        throw new Error(`OmCustomHTML: unknown property ${property}`);
    }
  }
}
