<template lang="pug">
div(:id="uid" :class="classObject" @click.stop="click" :type="eleType" :data-margin="dataMargin")
  slot
  .center-hoverbar.hoverbar(v-if="isCroppable && isColumnHovered && !isElementHovered")
    | {{ $t('singleClickToChange') }}
    br
    | {{ $t('dblClickToCrop') }}
</template>
<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import { checkMultipleElementInRow } from '@/editor/util';
  import { get as _get } from 'lodash-es';
  import ColorComponentsFactory from '@/utils/color-components/components';

  export default {
    props: {
      type: { type: String, required: true },
      item: { type: Object, required: true },
      index: { type: Number, required: false },
      page: { type: Object, required: false },
      row: { type: Object, required: false },
      column: { type: Object, required: false },
    },
    data: () => ({
      swipeOnMobile: false,
      isColumnHovered: false,
      isElementHovered: false,
      timeoutId: null,
    }),
    computed: {
      ...mapState([
        'dragInfo',
        'selectedElement',
        'editMode',
        'template',
        'mobilePreview',
        'selectedPage',
      ]),
      ...mapGetters(['elementsOfColumn', 'hasAccountFeature']),
      device() {
        return this.mobilePreview ? 'mobile' : 'desktop';
      },
      pageId() {
        if (this.type === 'canvas' || this.type === 'teaser') {
          return this.item.uid;
        }
        if (!this.page) {
          throw new Error('Required `page` prop is missing from Container.');
        }

        return this.page.uid;
      },

      rowId() {
        if (this.type === 'row') {
          return this.item.uid;
        }

        if (!this.row) {
          return null;
        }

        return this.row.uid;
      },

      columnId() {
        if (this.type === 'column') {
          return this.item.uid;
        }

        if (!this.column) {
          return null;
        }

        return this.column.uid;
      },

      elementId() {
        if (this.type === 'element') {
          return this.item.uid;
        }

        return null;
      },

      uid() {
        return this.type !== 'element' ? this.item.uid : '';
      },
      eleType() {
        if (this.type === 'element') {
          const inputType = _get(this.item, 'data.form.customSettings.type');
          if (inputType === 'radio' || inputType === 'checkbox') {
            return 'OmInput-picker';
          }
          return this.item.type;
        }
        return '';
      },
      dataMargin() {
        if (this.type === 'element') {
          return this.item.uid.replace('_', '_ma_');
        }
        return '';
      },
      classObject() {
        const result = {};
        if (this.type === 'column') {
          const widthClass = `col-${this.row.data.columnSplit[this.index]}`;
          result[widthClass] = true;
          result['_xs-12'] = true;
        }
        if (this.selectedElement && this.item.uid === this.selectedElement.uid) {
          result.selected = true;
        }
        if (
          this.type === 'row' &&
          this.selectedElement &&
          this.selectedElement.type === 'OmCol' &&
          this.item.uid === this.selectedElement.rowId
        ) {
          result.selected = true;
        }
        if (this.type === 'row' || this.type === 'column') {
          const isHiddenMobile = _get(this.item, 'mobile.hidden', false);
          const isHiddenDesktop = _get(this.item, 'desktop.hidden', false);

          result['xs-hidden'] = isHiddenMobile;
          result['xs-up-hidden'] = isHiddenDesktop;
        }
        result['om-mobile-swipe'] = this.swipeOnMobile;
        return result;
      },
      isCroppable() {
        if (this.type !== 'column') return false;
        const colorInstance = this.createBackgroundColorInstance();
        return colorInstance.getColoringType() === 'image';
      },
    },

    mounted() {
      if (this.type === 'row') {
        this.checkMultipleElements();
        if (this.$bus) this.$bus.$on('checkElementNumber', this.checkMultipleElements);
      }

      this.$bus?.$on?.('mouseEnterElementOnCanvas', ({ uid }) => {
        if (this.item.uid === uid) {
          this.isColumnHovered = true;
        }
      });

      this.$bus?.$on?.('elementHovered', (val) => {
        this.isElementHovered = val;
      });

      this.$bus?.$on?.('mouseLeaveElementOnCanvas', ({ uid }) => {
        if (this.item.uid === uid) {
          this.isColumnHovered = false;
        }
      });
    },
    beforeDestroy() {
      if (this.$bus) this.$bus.$off('checkElementNumber', this.checkMultipleElements);
    },

    methods: {
      ...mapActions(['selectElement']),
      click(e) {
        if (e.target.closest('.om-col-resizer') || e.target.closest('.om-page-resizer')) {
          return;
        }

        if (this.type === 'teaser' || this.type === 'canvas') {
          this.selectElement({ uid: this.selectedPage.uid, target: e.target });
        } else if (this.type === 'row' || this.type === 'column' || this.type === 'element') {
          this.selectElement({ uid: this.item.uid, target: e.target });
        }

        if (this.isColumnWithImageClicked(e)) {
          if (!this.timeoutId) {
            this.timeoutId = setTimeout(() => {
              // single click
              this.timeoutId = null;
              const colorInstance = this.createBackgroundColorInstance();
              const rect = e.srcElement.closest('.selected').getBoundingClientRect();
              const wrapperRect = document
                .querySelector('.om-workspace-wrapper')
                .getBoundingClientRect();

              const params = {
                top: rect.top + wrapperRect.y,
                left: rect.left + wrapperRect.x,
                property: `${this.device}.background.color`,
                component: this.type,
                colorInstance,
              };

              window.parent.om.store.dispatch('showColorPicker', params);
            }, 450);
          } else {
            // double click
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
            if (this.isColumnWithImageClicked(event)) {
              this.$bus?.$emit?.('showImageCropper', {
                colorInstance: this.createBackgroundColorInstance(),
                actionLocation: 'workspace',
              });
              this.$bus?.$emit?.('deselectAll');
            }
          }
        }

        this.$bus?.$emit?.('hideColorPicker');
        this.$bus?.$emit?.('hideCalendar');
      },
      setSwipeOnMobile(productsLength, columnLength) {
        this.swipeOnMobile = productsLength > 1 && columnLength > 1;
      },
      checkMultipleElements() {
        if (this.type === 'row') {
          const products = checkMultipleElementInRow('.om-product', `#${this.uid}`);
          const columns = checkMultipleElementInRow('.canv-col', `#${this.uid}`);
          this.setSwipeOnMobile(products.length, columns.length);
        }
      },
      createBackgroundColorInstance() {
        return new ColorComponentsFactory(
          this.type,
          this.item,
          `${this.device}.background.color`,
          [],
          false,
        );
      },
      isColumnWithImageClicked(event) {
        // Ahol quill van (pl text, button) ott nem akarjuk hogy duplaklikkel felnyíljon a cropper,
        // hátha az adott szót akarta kijelölni duplaklikkel
        return (
          !event.target.classList.contains('om-dtr-content') &&
          !event.target.parentNode?.classList.contains('om-dtr-content') &&
          this.isCroppable
        );
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../sass/variables/variables'

  .center-hoverbar
    position: absolute
    top: 50% !important
    left: 50% !important
    margin-right: -50%
    transform: translate(-50%, -50%)
    font-weight: bold
    font-family: "Roboto", sans-serif !important
    color: #ffffff
    font-size: 0.75rem !important
    border-radius: 4px !important
</style>
