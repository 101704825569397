<template lang="pug">
div(
  :id="`${item.uid}_wrapper`"
  ref="containerRef"
  :class="{ ...classObj, 'om-full': !isTeaser }"
  :style="containerStyle()"
)
  div(
    v-if="(selectedElement && selectedElement.uid === item.uid) || item.selected"
    @click.stop=""
    @click="quillContentClicked"
    @keyup="onKeyup"
    key="edit"
    :id="item.uid"
    :class="editorClass"
    spellcheck="false"
    :data-event-settings="btnEventSettings"
    :data-success-copy-text="successCopyText"
    :data-failure-copy-text="failureCopyText"
  )
  div(
    v-else
    :id="item.uid"
    key="display"
    :class="elementClass"
    v-html="content"
    :data-event-settings="btnEventSettings"
    :data-success-copy-text="successCopyText"
    :data-failure-copy-text="failureCopyText"
  )
</template>

<script>
  import Quill from 'quill';
  import 'quill-emoji';
  import 'quill-emoji/dist/quill-emoji.css';
  import { get as _get } from 'lodash-es';
  import tinycolor from 'tinycolor2';

  // CHORE: DELETE THIS COMMENT LINE (JUST FOR TRIGGERING BUILD)

  import { mapMutations, mapState, mapGetters, createNamespacedHelpers } from 'vuex';
  import {
    disableLinkClicks,
    findAncestor,
    isUrl,
    websafeFontListing,
    _slicedToArray,
    isElementHiddenInView,
  } from '@/editor/util';
  import { registerEmoji, repositionEmojiPalette } from '@/editor/util/Quill/emojiRegistry';
  import {
    handleSmartTagClick,
    registerSmartTag,
    cleanUpSmartTag,
  } from '@/editor/util/Quill/smartTagRegistry';
  import {
    fontSizeOptions,
    fontWeightOptions,
    lineHeightOptions,
    smartTagsOptions,
  } from '@/editor/quill_options';

  import { CustomLinkSanitizer } from '@/editor/util/Quill/customLink';
  import { QuillOmColor } from '@/utils/color-components/coloring';

  import {
    createPickerFontSearch,
    destroyPickerFontSearch,
  } from '@/editor/util/quillPickerFontSearch';
  // eslint-disable-next-line
  import leftAlignSvg from '!!raw-loader!@/assets/editor/svg/quill-left-align.svg';
  // eslint-disable-next-line
  import arrowSvg from '!!raw-loader!@/assets/editor/svg/quill-arrow.svg';

  const { mapGetters: customThemeGetters, mapMutations: customThemeMutations } =
    createNamespacedHelpers('customTheme');

  const SUPPORTED_FORMATS = [
    'align',
    'italic',
    'underline',
    'strike',
    'uppercase',
    'color',
    'omcolor',
    'omcolorclass',
    'link',
    'font',
    'size',
    'lineheight',
    'weight',
    'emoji',
    'smart-tag',
  ];

  export default {
    props: ['elementClass', 'item', 'extraClass'],
    data() {
      return {
        colorInstance: null,
        textChanged: false, // used for feature usage telemetry
        originalHtml: null, // used for feature usage telemetry
        height: null,
        editorRef: null,
        undoLength: 1,
        formatCache: {},
        lastFont: null,
        lastWeight: null,
        lastSize: null,
        lastFormat: null,
        rangeErrorHappened: false,
        newFirstLineInserted: false,
        customDropdownComponents: [
          // {name: 'spacing', style: 'letter-spacing', selector: '.ql-spacing', label: 'Spacing', block: false},
          {
            name: 'lineheight',
            style: 'line-height',
            selector: '.ql-lineheight',
            label: 'Line Height',
            block: true,
          },
          {
            name: 'weight',
            style: 'font-weight',
            selector: '.ql-weight',
            label: 'Weight',
            block: false,
          },
          {
            name: 'smart-tags',
            style: 'smart-tags',
            selector: '.ql-smart-tags',
            label: this.$i18n.t('smartTags.label'),
            block: false,
          },
        ],
      };
    },
    computed: {
      ...mapState(['mobilePreview', 'template', 'selectedElement']),
      ...mapGetters(['installedFonts']),
      ...customThemeGetters(['getElementsByUid']),
      successCopyText() {
        return this.item.data?.coupon?.successCopyText;
      },
      failureCopyText() {
        return this.item.data?.coupon?.failureCopyText;
      },
      content() {
        const dtrContent = cleanUpSmartTag(this.item.data.text);
        const result = dtrContent || this.item.data.text || this.$t('typeHere');
        return result.replace(/<span class="ql-cursor"><\/span>/g, '');
      },
      classObj() {
        const result = {};
        let type;
        if (this.isButton) {
          type = 'button';
        } else if (this.isText) {
          type = 'text';
        }
        if (this.extraClass === null) {
          const typeStyle = this.template.style[type];
          if (typeStyle && typeStyle.textJustify) result[typeStyle.textJustify] = true;
        } else if (Array.isArray(this.extraClass)) {
          this.extraClass.forEach((extraClass) => {
            result[extraClass] = true;
          });
        } else {
          result[this.extraClass] = true;
        }
        return result;
      },
      isButton() {
        return this.item.type.includes('Button');
      },
      isText() {
        return this.item.type.includes('Text');
      },
      isTeaser() {
        return this.item.type.includes('Teaser');
      },
      fontFormatOptions() {
        return this.installedFonts
          .concat(websafeFontListing)
          .concat({ key: 'om-font-1', family: 'om-font-1' })
          .concat({ key: 'om-font-2', family: 'om-font-2' })
          .map(({ key, family }) => ({ value: key, label: family }));
      },
      fontSizeOptions() {
        return fontSizeOptions;
      },
      fontWeightOptions() {
        return fontWeightOptions;
      },
      lineHeightOptions() {
        return lineHeightOptions;
      },

      editorClass() {
        return [`editor-${this.item.uid}`, this.elementClass];
      },
      editorToolbarClass() {
        const edClass = this.editorClass[0];
        return `${edClass}Toolbar`;
      },
      btnEventSettings() {
        return this.isButton
          ? JSON.stringify({
              action: this.item.data.action,
              url: this.item.data.redirectUrl,
              phoneNumber: this.item.data.phoneNumber,
              newTab: this.item.data.newTab,
              keepQueryParams: this.item.data.keepQueryParams,
              jumpTo: +this.item.data.jumpToPage + 1,
              reportAs: this.item.data.reportAs,
              isFilled: this.item.data.status,
              syncToIntegration: this.item.data.syncToIntegration,
            })
          : null;
      },
      templatePalette() {
        const hexColors = [];
        if (!this.template?.palette) {
          return hexColors;
        }
        const themeColors = this.template.style.palette?.themeColors?.map(
          ({ themeColor }) => themeColor,
        );
        const colors = [...this.template.palette];
        if (themeColors) colors.push(...themeColors);

        colors.forEach((color) => {
          if (color.match(/^rgb\(.*\)$/)) {
            return hexColors.push(tinycolor(color).toHexString());
          }
          hexColors.push(color);
        });
        return hexColors;
      },
      omColor() {
        const color = this.colorInstance.getColor();
        if (Number.isInteger(color)) {
          return this.templatePalette[color];
        }
        return color;
      },
      defaultColorHex() {
        const defaultColorFromElement = this.selectedElement?.desktop?.color;

        if (!defaultColorFromElement) {
          return '#ffffff';
        }
        if (Number.isInteger(defaultColorFromElement)) {
          return this.templatePalette[defaultColorFromElement] ?? '#ffffff';
        }
        if (defaultColorFromElement.match(/^rgb\(.*\)$/)) {
          return tinycolor(defaultColorFromElement).toHexString();
        }

        return defaultColorFromElement;
      },
      isHiddenOnDevice() {
        const device = this.mobilePreview ? 'mobile' : 'desktop';
        return this.item?.[device]?.hidden;
      },
    },
    watch: {
      // eslint-disable-next-line
      'item.selected': function (n) {
        if (n) this.show();
        else this.hide();
      },
      // eslint-disable-next-line
      'selectedElement.uid': function (newId, oldId) {
        if (newId === this.item.uid && oldId !== this.item.uid) {
          // item was unselected but became selected
          const hidden = isElementHiddenInView(
            this.item,
            this.mobilePreview ? 'mobile' : 'desktop',
          );

          if (!hidden) {
            this.show();
          }
        } else if (oldId === this.item.uid && newId !== this.item.uid) {
          // item was selected but became unselected
          this.hide();
        }
      },

      mobilePreview(v) {
        const hidden = isElementHiddenInView(this.item, v ? 'mobile' : 'desktop');

        if (hidden) {
          this.hide();
        } else if (
          (this.selectedElement && this.item.uid === this.selectedElement.uid) ||
          this.item.selected
        ) {
          this.show();
        }
      },

      // eslint-disable-next-line
      'item.mobile.hidden': function (v) {
        if (v && this.mobilePreview) {
          this.hide();
        }

        if (
          !v &&
          this.mobilePreview &&
          ((this.selectedElement && this.item.uid === this.selectedElement.uid) ||
            this.item.selected)
        ) {
          this.show();
        }
      },

      // eslint-disable-next-line
      'item.desktop.hidden': function (v) {
        if (v && !this.mobilePreview) {
          this.hide();
        }

        if (
          !v &&
          !this.mobilePreview &&
          ((this.selectedElement && this.item.uid === this.selectedElement.uid) ||
            this.item.selected)
        ) {
          this.show();
        }
      },

      installedFonts(n) {
        if (
          !n.length ||
          !this.selectedElement ||
          this.item.uid !== this.selectedElement.uid ||
          !this.item.selected
        )
          return;

        this.show();
      },
    },

    mounted() {
      const init = () => {
        const isSameSelected =
          (this.selectedElement && this.item.uid === this.selectedElement.uid) ||
          this.item.selected;
        if (isSameSelected && !this.isHiddenOnDevice) {
          this.initWithToolbar();
        } else {
          this.hide();
        }
      };

      init();
      if (this.$bus) {
        this.$bus.$on('historyChanged', init);
        this.$bus.$on('refreshWysiwyg', (uid, needToolbar) => {
          if (this.item.uid === uid) {
            this.reinitQuill(needToolbar);
          }
        });
      }
    },
    methods: {
      ...mapMutations(['setStateAttr']),
      ...customThemeMutations(['modifyElementByUid']),
      quillContentClicked(e) {
        const smartTagElement = findAncestor(e.target, 'smart-tag');
        if (!smartTagElement) return;

        const Parchment = Quill.import('parchment');
        const parchmentElement = Parchment.find(smartTagElement);
        window.editorRef.setSelection(parchmentElement.offset(window.editorRef.scroll), 1, 'user');
        handleSmartTagClick(smartTagElement);
      },
      emitCustomThemeModifications() {
        const isModified = this.getElementsByUid(this.selectedElement.uid);

        if (!isModified) {
          this.modifyElementByUid({ uid: this.selectedElement.uid, value: true });
        }
      },
      reinitQuill(needToolbar) {
        this.lastFormat = {};
        this.hide();
        this.show(needToolbar);
      },
      containerStyle() {
        const style = {};
        if (this.height) {
          style.height = `${this.height}px`;
        }
        return style;
      },
      initWithToolbar() {
        const quillCursors = [...document.querySelectorAll(`#${this.item.uid} .ql-cursor`)];
        if (quillCursors.length) quillCursors.forEach((cursor) => cursor.remove());
        const hideHash = { attr: 'quillToolbarInfo', value: { show: false } };
        const showHash = {
          attr: 'quillToolbarInfo',
          value: { show: true, toolbarSelector: this.editorToolbarClass },
        };
        this.$nextTick(() => {
          this.setStateAttr(hideHash);
          this.$nextTick(() => {
            this.setStateAttr(showHash);
            this.$nextTick(() => {
              this.initEditor();
            });
          });
        });
      },
      saveHistory() {
        const quillUndoLength = this.editorRef.history.stack.undo.length;
        if (quillUndoLength > this.undoLength) {
          this.undoLength = quillUndoLength;
        }
      },
      loadFormats(format, cache) {
        if (cache) {
          Object.keys(this.formatCache).forEach((key) => {
            let value = this.formatCache[key];
            if (Array.isArray(value)) value = value[0];
            // silent so text-change is no triggered on formatting
            this.editorRef.formatText(0, 1, key, value, 'silent');
            this.editorRef.format(key, value, 'silent');
            format[key] = value;
          });
        }
        const { omcolorclass, color, uppercase } = format;

        const defaultColorValue = this.defaultColorHex;

        if (omcolorclass) {
          const index = omcolorclass === 'main' ? 0 : Number(omcolorclass.replace('c', ''));
          const colorValue = this.templatePalette[index] ?? defaultColorValue;
          this.colorInstance.setColor(index);
          this.setToolbarAttr('omcolor', colorValue);
        } else if (color) {
          this.colorInstance.setColor(color);
          this.setToolbarAttr('omcolor', color);
        } else {
          this.colorInstance.setColor(defaultColorValue);
          this.setToolbarAttr('omcolor', defaultColorValue);
        }

        this.setToolbarAttr('uppercase', uppercase === 'uppercase');
        this.customDropdownComponents.forEach((inline) => {
          let value = format[inline.name];
          // check is format contains multiple values, like weigth [400, 700]
          const multipleValues = typeof value === 'object' && Object.keys(value).length > 1;
          if (multipleValues) value = null;
          // load weight labels
          if (value && inline.name === 'weight') {
            const foundOption = fontWeightOptions.find((o) => o.value === value);
            if (foundOption) value = foundOption.label;
          }
          const label = value || inline.label;
          const pickerSelector = `${inline.selector} .ql-picker-label`;
          const picker = document.querySelector(pickerSelector);
          if (picker) {
            picker.innerHTML = label + arrowSvg;
          }
        });
      },

      smartTagsHandler(optionValue) {
        const currentSelection = this.editorRef.getSelection();
        const getLabelByValue = smartTagsOptions.find(({ value }) => value === optionValue);
        if (optionValue.includes('smart_product_tag')) {
          this.$bus.$emit('create-smart-tag', optionValue);
          return;
        }
        this.$bus.$emit('smart-tag-modal', {
          new: {
            currentSelection,
            optionValue,
            label: getLabelByValue?.key,
          },
        });
      },

      getChars(code) {
        switch (code) {
          case 8: // backspace
            return '';
          case 13: // enter
            return '\n';
          default:
            return String.fromCharCode(code);
        }
      },

      onKeyup(event) {
        if (this.rangeErrorHappened) {
          this.rangeErrorHappened = false;
          if (event?.which) {
            this.editorRef.insertText(0, this.getChars(event.which));
          }
        }
      },

      isRealEmpty() {
        const contents = this.editorRef.getContents();
        return !contents?.ops?.some?.(({ insert }) => !!insert?.['smart-tag']);
      },

      initEditor() {
        this.colorInstance = new QuillOmColor(this.templatePalette);

        const self = this;
        this.setDivContainer();
        // registerBuiltInComponent('attributors/style/size', this.fontSizeOptions)
        this.registerBuiltInComponent('formats/font', this.fontFormatOptions);
        this.customDropdownComponents.forEach((i) =>
          this.setupInlineFormat(i.name, i.style, i.block),
        );
        this.setupInlineFormat('uppercase', 'text-transform');
        this.setupInlineFormat('omcolor', 'color');
        const Parchment = Quill.import('parchment');
        const themeColorsExists =
          this.template.style.palette.themeColors && this.template.style.palette.themeColors.length;
        let colorsLength = parseInt(this.template.palette?.length, 10);
        if (themeColorsExists)
          colorsLength += parseInt(this.template.style.palette.themeColors.length, 10);
        const inlineClass = new Parchment.Attributor.Class('omcolorclass', 'om-color-palette', {
          scope: Parchment.Scope.INLINE,
          whitelist: ['main', ...Array.from(new Array(colorsLength), (_, index) => `c${index}`)], // c0 = main
        });
        Quill.register(inlineClass, true);
        this.overrideLeftAlign();
        this.overrideFontSize(this.item.type);

        delete this.editorRef;
        delete window.editorRef;

        const editor = new Quill(`.${this.editorClass}`, {
          formats: SUPPORTED_FORMATS,
          modules: {
            toolbar: {
              container: `.${this.editorToolbarClass}`,
              handlers: {
                btn: () => {
                  if (this.$bus) this.$bus.$emit('showFontManager');
                },
                emoji: () => {},
                'smart-tags': this.smartTagsHandler,
              },
            },
            clipboard: { matchVisual: false },
            'emoji-toolbar': true,
            'emoji-shortname': true,
          },
          theme: 'snow',
        });
        this.editorRef = editor;
        window.editorRef = editor;
        this.removeAccentedBinding(editor);
        this.overrideUndoRedo(editor);
        this.monkeyPatchGetFormat(editor, this.template.style, this.item.type);
        this.monkeyPatchLinkSave(editor);
        this.customDropdownComponents.forEach((i) => this.setupCustomDropdown(i.selector, i.label));
        registerEmoji(editor);
        repositionEmojiPalette();
        registerSmartTag();

        setTimeout(() => {
          // keep format on paste
          editor.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
            delta.ops = delta.ops.map((op) => {
              const restoreKeys = ['color', 'omcolorclass', 'lineheight', 'weight', 'size'];
              const lhOptions = self.lineHeightOptions.map((m) => m.value.toString());
              const whOptions = self.fontWeightOptions.map((m) => m.value.toString());
              const fsOptions = self.fontSizeOptions.map((m) => m.value.toString());
              const attributes = {};
              if (op.attributes) {
                restoreKeys.forEach((key) => {
                  const value = op.attributes[key];
                  if (value) {
                    if (
                      key === 'lineheight' &&
                      lhOptions.find((o) => (o === value.toString()) === undefined)
                    ) {
                      console.log('lineheight invalid', value);
                      return;
                    }
                    if (
                      key === 'weight' &&
                      whOptions.find((o) => (o === value.toString()) === undefined)
                    ) {
                      console.log('weight invalid', value);
                      return;
                    }
                    if (
                      key === 'size' &&
                      fsOptions.find((o) => (o === value.toString()) === undefined)
                    ) {
                      console.log('size invalid', value);
                      return;
                    }
                    attributes[key] = value;
                  }
                });
              }

              SUPPORTED_FORMATS.forEach((key) => {
                if (!attributes[key] && this.formatCache[key]) {
                  attributes[key] = this.formatCache[key];
                }
              });
              return {
                insert: op.insert,
                attributes,
              };
            });
            return delta;
          });
        }, 100);

        editor.on('text-change', (e) => {
          if (
            e.ops?.length <= 2 &&
            (e.ops[e.ops.length - 1]?.insert || e.ops[e.ops.length - 1]?.delete)
          ) {
            // Should check selected element old teaser does not have selectedElement
            if (this.selectedElement?.data) {
              // user typed
              this.selectedElement.data.originalText = null;
            }
          }

          const selection = editor.getSelection();
          const [leaf] = editor.getLeaf(selection?.index);
          if (this.newFirstLineInserted) {
            this.loadFormats(this.formatCache, true);
          }
          this.newFirstLineInserted = leaf?.statics?.name === 'Break';

          const html = editor.root.innerHTML.replace(/\uFEFF/g, '');
          if (!this.originalHtml) {
            this.originalHtml = html;
          } else if (!this.textChanged && this.originalHtml !== html) {
            this.$bus.$emit('userInputChange', { property: 'WYSIWYG', isTeaser: this.isTeaser });
            this.textChanged = true;
          }

          if (this.isTeaser) {
            this.$emit('textChange', html);
          } else if (self.item.data.text.replace(/\uFEFF/g, '') !== html) {
            // eslint-disable-next-line
            self.item.data.text = html;
            this.emitCustomThemeModifications();
          }

          // disable links on insert
          e.ops.forEach((op) => {
            if (op.attributes && op.attributes.link) disableLinkClicks();
          });

          if (e.ops[e.ops.length - 1]?.retain) {
            const dtrContent = cleanUpSmartTag(html);
            if (!dtrContent) return;

            // eslint-disable-next-line
            self.item.data.text = dtrContent;
          }
        });

        // so on copy paste we keep the original format
        editor.on('selection-change', (range) => {
          if (!range) {
            const textLength = editor.getText().trim().length;
            // recover from "The given range isn't in document." error
            if (textLength === 0 && this.isRealEmpty()) {
              this.rangeErrorHappened = true;
              editor.setSelection(0);
            }
            this.lastFormat = null;
            return;
          }
          this.lastFormat = editor.getFormat(0, 1);
          const format = editor.getFormat(range);

          if (range?.length > 0) this.formatCache = format;
          this.loadFormats(format);
        });
        self.$nextTick(() => {
          editor.root.innerHTML = self.content;
          self.$nextTick(() => {
            // set focus on last element
            editor.setSelection(0, self.content.length);

            if (self.item.desktop) {
              const { quillFormat } = self.item.desktop;
              if (quillFormat && self.item.uid === self.$store.state.lastInsertedElementId) {
                for (const [key, value] of Object.entries(quillFormat)) {
                  editor.format(key, value);
                }

                self.$store.state.lastInsertedElementId = null;
              }
            }
          });
          self.height = null;
          const colorButtons = document.querySelectorAll('.ql-color-picker');
          colorButtons.forEach((cb) => {
            cb.addEventListener('click', (e) => {
              e.stopPropagation(); // dont hide color picker on click
              const target = findAncestor(e.target, 'ql-color-picker');
              const innerHeight = window.innerHeight;
              const innerWidth = window.innerWidth;
              const clientRect = target.getClientRects()[0];
              const colorPickerHeight = 520;
              const colorPickerWidth = 270;
              let top;
              let left;

              if (this.mobilePreview) {
                const iframeRect = parent.window.document
                  .getElementById('workspaceFrame')
                  .getClientRects()[0];
                left =
                  parent.window.innerWidth - iframeRect.x < colorPickerWidth
                    ? iframeRect.x - colorPickerWidth + 7
                    : iframeRect.x + iframeRect.width + 12;
                top = iframeRect.y + iframeRect.height / 2 - 408 / 2;
              } else {
                // calculate top
                const topOffset = parent.window.innerHeight - innerHeight;
                if (innerHeight - clientRect.bottom < colorPickerHeight) {
                  top = topOffset + clientRect.top - colorPickerHeight;
                } else {
                  top = topOffset + clientRect.bottom;
                }
                // calculate width
                const leftOffset = parent.window.innerWidth - window.innerWidth;
                if (innerWidth - clientRect.left < colorPickerWidth) {
                  left = innerWidth + leftOffset - colorPickerWidth;
                } else {
                  left = leftOffset + clientRect.left;
                }
              }
              const rects = cb.getBoundingClientRect();
              if (!this.mobilePreview) {
                const topOffset = parent.window.innerHeight - innerHeight;
                if (innerHeight - clientRect.bottom < colorPickerHeight) {
                  top = Math.abs(topOffset + clientRect.top - colorPickerHeight);
                }
                left = rects.left + 107;
              }

              const params = {
                top,
                left,
                source: this.editorClass,
                value: this.omColor,
                property: 'omcolor',
                alpha: false,
                colorInstance: this.colorInstance,
              };
              self.$bus.$emit('showColorPicker', params);
            });
          });

          document.querySelector('.ql-uppercase').addEventListener('click', () => {
            const range = editor.selection.savedRange;
            if (range && range.length > 0) {
              const format = editor.getFormat(range);
              if (format.uppercase === '') {
                editor.formatText(range.index, range.length, 'uppercase', 'uppercase');
                this.setToolbarAttr('uppercase', true);
              } else {
                editor.formatText(range.index, range.length, 'uppercase', false);
                this.setToolbarAttr('uppercase', false);
              }

              this.$bus.$emit('historySave');
            }
          });
          // resposition link editor if toolbarUnderElement
          document.querySelector('.ql-picker-link').addEventListener('click', () => {
            const selected = document.querySelector('.om-element.selected');
            const { top: selectedTop, height: selectedHeight } = selected.getClientRects()[0];

            const toolbar = document.querySelector('.ql-toolbar');
            const { top: toolbarTop, height: toolbarHeight } = toolbar.getClientRects()[0];
            const linkEditor = document.querySelector('.ql-tooltip.ql-editing');
            const { top: linkEditorTop, height: linkEditorHeight } = linkEditor.getClientRects()[0];

            if (linkEditor && toolbarTop + toolbarHeight > linkEditorTop + linkEditorHeight) {
              toolbar.style.display = 'none';

              document.querySelector('.ql-action').addEventListener('click', () => {
                const value = linkEditor.children[1].value;
                const urlRegex =
                  /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;
                const isValidURL = urlRegex.test(value);
                if (!isValidURL) return;
                toolbar.style.display = 'block';
              });

              document.querySelector('.ql-remove').addEventListener('click', () => {
                toolbar.style.display = 'block';
              });
            }

            const isToolbarUnderElement = selectedTop < toolbarTop;
            if (isToolbarUnderElement) {
              const topModifier = 75 + selectedHeight;
              if (linkEditor) linkEditor.style.top = `${topModifier}px`;

              this.$bus.$emit('historySave');
            }
          });

          this.$bus.$off('QuillColorUpdate');

          this.$bus.$on('QuillColorUpdate', () => {
            // Fixes focus loss of color picker input in FF and Edge
            if (window.getSelection()) window.getSelection().removeAllRanges();
            const range = editor.selection.savedRange;
            if (!range) {
              return;
            }

            const toolbarClasses = document.querySelector('.ql-toolbar').classList.value.split(' ');
            const toolbarSelector = toolbarClasses.find((e) => e.startsWith('editor-ele_'));
            const editorId = editor.container.id;
            const updateForThisEditor = toolbarSelector.indexOf(editorId) > -1;
            if (!updateForThisEditor) {
              return;
            }

            const { property, value, isOmColorClass } = this.colorInstance.getValues();
            const update = {
              color: {
                property,
                toolBar: 0,
                format: 0,
              },
              class: {
                property: `${property}class`,
                format: 0,
              },
            };
            if (isOmColorClass) {
              update.color.toolBar = this.templatePalette[value] ?? '#ffffff';
              update.class.format = value ? `c${value}` : 'main';
            } else {
              update.color.toolBar = value;
              update.color.format = value;
            }

            this.setToolbarAttr(update.color.property, update.color.toolBar);

            editor.formatText(
              range.index,
              range.length,
              update.color.property,
              update.color.format,
            );
            editor.formatText(
              range.index,
              range.length,
              update.class.property,
              update.class.format,
            );
          });

          const toolbarClass = `.${this.editorToolbarClass}`;
          const toolbar = document.querySelector(toolbarClass);
          toolbar.addEventListener('click', (e) => {
            e.stopPropagation();
          });
          const pickers = document.querySelectorAll('.ql-picker');
          pickers.forEach((p) => {
            p.addEventListener('click', (e) => {
              const isFontPicker = p.classList.contains('ql-font');
              const wasOpened = !p.classList.contains('ql-expanded');

              const fontOpts = p.querySelector('.ql-picker-options');
              const height = fontOpts.clientHeight;

              if (
                toolbar.offsetTop + height > 650 ||
                (this.mobilePreview && toolbar.offsetTop + height > 500)
              ) {
                fontOpts.style.position = 'absolute';
                fontOpts.style.top = `-${height}px`;
                fontOpts.classList.add('ql-picker-options-top');
              } else {
                fontOpts.style.position = '';
                fontOpts.style.top = '';
                fontOpts.classList.remove('ql-picker-options-top');
              }

              if (isFontPicker && !wasOpened) {
                createPickerFontSearch(p);
              }
              if (!isFontPicker || wasOpened) {
                destroyPickerFontSearch();
              }

              const picker = findAncestor(e.target, 'ql-picker');
              const selectedValue = picker.children[0].dataset.value;
              const targetClass = picker.classList[0];
              if (targetClass !== 'ql-color-picker') {
                // normal dropdown
                this.removeClassFromSelector(`.ql-expanded:not(.${targetClass})`, 'ql-expanded');
                window.parent.om.bus.$emit('hideColorPicker');

                if (selectedValue) {
                  // scroll to selected value
                  const pickerItem = document.querySelector(
                    `.${targetClass} .ql-picker-item[data-value="${selectedValue}"]`,
                  );
                  // scroll to the "middle" of a long dropdown like font size
                  document.querySelector(`.${targetClass} .ql-picker-options`).scrollTop =
                    pickerItem.offsetTop - 50;
                  pickerItem.classList.add('ql-selected');
                }
              } else {
                // color picker, close all dropdown
                this.removeClassFromSelector('.ql-expanded', 'ql-expanded');
                this.$bus.$emit('historySave');
              }
            });
          });
        });
      },
      show(needToolbar = true) {
        this.$nextTick(() => {
          const containerRects = this.$refs.containerRef.getClientRects()[0];
          if (containerRects) this.height = containerRects.height;
        });
        if (needToolbar) {
          this.initWithToolbar();
        }
      },
      hide() {
        this.setStateAttr({ attr: 'quillToolbarInfo', value: { show: false } });
        this.$nextTick(() => disableLinkClicks());
      },
      overrideLeftAlign() {
        const Parchment = Quill.import('parchment');
        const alignConfig = {
          scope: Parchment.Scope.BLOCK,
          whitelist: ['left', 'center', 'right'],
        };
        const alignClass = new Parchment.Attributor.Class('align', 'ql-align', alignConfig);
        Quill.register(alignClass, true);
        const icons = Quill.import('ui/icons');
        icons.align.left = leftAlignSvg;
      },
      overrideFontSize(type) {
        let className = '';
        if (type.includes('Button')) {
          className = 'om-button-fontsize';
        } else if (type.includes('Text')) {
          className = 'om-text-fontsize';
        } else if (type.includes('Teaser')) {
          className = 'om-teaser-fontsize';
        } else {
          className = 'om-fontsize';
        }
        const Parchment = Quill.import('parchment');
        const whitelist = fontSizeOptions.map((o) => `${o.px}`);
        const fontSizeConfig = { scope: Parchment.Scope.INLINE, whitelist };
        const fontSizeClass = new Parchment.Attributor.Class('size', className, fontSizeConfig);
        Quill.register(fontSizeClass, true);
      },
      removeAccentedBinding(editor) {
        const desiredKeyCode = 186;
        const bindings = editor.keyboard.bindings;
        if (bindings.hasOwnProperty(desiredKeyCode)) {
          delete editor.keyboard.bindings[desiredKeyCode];
        }
      },
      async overrideUndoRedo(editor) {
        // remove quill default undo & redo keybinding
        delete editor.keyboard.bindings[90];

        // override quill default undo
        editor.keyboard.addBinding({ key: 90, metaKey: true }, () =>
          this.$bus.$emit('undo-redo', { type: 'undo' }),
        );

        // override quill default redo
        editor.keyboard.addBinding({ key: 90, metaKey: true, shiftKey: true }, () =>
          this.$bus.$emit('undo-redo', { type: 'redo' }),
        );
      },
      monkeyPatchLinkSave(editor) {
        const originalSave = editor.theme.tooltip.save;
        editor.theme.tooltip.save = function save() {
          const url = this.textbox.value;
          const sanitizedUrl = CustomLinkSanitizer.sanitize(url);
          if (isUrl(sanitizedUrl)) {
            this.textbox.classList.remove('invalid');
            this.textbox.value = sanitizedUrl;
            // eslint-disable-next-line
            originalSave.apply(this, arguments);
          } else {
            this.textbox.classList.add('invalid');
          }
        };
      },
      monkeyPatchGetFormat(editor, style, type) {
        const self = this;
        const originalGetFormat = editor.getFormat;

        const setupFont = (format, globalStyle) => {
          if (format.font) {
            return format;
          }

          const fontsSet = self.fontFormatFromEditor(editor);
          let font;
          if (fontsSet.size === 1) {
            font = Array.from(fontsSet)[0];
            this.lastFont = font;
            this.lastWeight = format.weight;
            this.lastSize = format.size;
          } else if (fontsSet.size === 0) {
            if (this.lastFont) {
              font = this.lastFont;
            } else {
              const globalFont =
                style.canvas && style.canvas.fontFamily ? style.canvas.fontFamily : 'Open Sans';
              font = globalStyle.fontFamily || globalFont;
              font = font.toLowerCase().replace(/ /g, '-');
            }
          }

          format.font = font;
          return format;
        };

        editor.getFormat = function getFormat() {
          let globalStyle;
          if (self.isButton) {
            globalStyle = style.button;
          } else if (self.isText) {
            globalStyle = style.text;
          } else if (self.isTeaser) {
            globalStyle = style.tab;
          }
          // eslint-disable-next-line
          let format = originalGetFormat.apply(this, arguments);
          format = setupFont.call(this, format, globalStyle);

          if (!format.size) {
            if (self.lastSize) {
              format.size = self.lastSize;
            } else if (self.lastFormat) {
              format.size = self.lastFormat.size;
            } else {
              format.size = globalStyle.fontSize;
            }
          }

          if (!format.align && !self.isButton && !type.includes('Teaser')) {
            format.align = _get(style, 'text.textAlign');
          }

          // if (!format.size) format.size = globalStyle.fontSize || 16
          if (!format.weight) {
            if (self.lastWeight) {
              format.weight = self.lastWeight;
            } else if (self.lastFormat) {
              format.weight = self.lastFormat.weight;
            } else {
              format.weight = globalStyle.textWeight ? '700' : '400';
            }
          }

          if (!format.lineheight) {
            if (self.lastFormat) {
              format.lineheight = self.lastFormat.lineheight;
            } else {
              format.lineheight = 1;
            }
          }

          if (type.includes('Teaser')) {
            if (!format.color && globalStyle.color) {
              format.color = globalStyle.color;
            }

            if (!format.size && globalStyle.fontSize) {
              format.size = globalStyle.fontSize;
            }
          }

          if (self.lastFormat) {
            const { strike, underline, italic, color } = self.lastFormat;

            if (!format.strike && strike) {
              format.strike = strike;
            }
            if (!format.underline && underline) {
              format.underline = underline;
            }
            if (!format.italic && italic) {
              format.italic = italic;
            }
            if (!format.color && color) {
              format.color = color;
            }
          }

          return format;
        };
      },
      setToolbarAttr(attr, value) {
        this.setStateAttr({ stateAttr: 'quillToolbarInfo', attr, value });
      },
      removeClassFromSelector(selector, className) {
        document.querySelectorAll(selector).forEach((pi) => pi.classList.remove(className));
      },
      setDivContainer() {
        const Block = Quill.import('blots/block');
        Block.tagName = 'DIV';
        Block.className = 'om-dtr-content';
        Quill.register(Block, true);
      },
      setupInlineFormat(name, style, blockScope = false) {
        const Parchment = Quill.import('parchment');
        const inlineStyle = new Parchment.Attributor.Style(name, style, {
          scope: blockScope ? Parchment.Scope.BLOCK : Parchment.Scope.INLINE,
        });
        Quill.register(inlineStyle, true);
      },
      registerBuiltInComponent(importPath, valuesArray) {
        const imported = Quill.import(importPath);
        if (valuesArray) {
          imported.whitelist = valuesArray.map((o) => o.value);
        }
        Quill.register(imported, true);
      },
      setupCustomDropdown(uniqueSelector, label) {
        const pickerSelector = `${uniqueSelector} .ql-picker-label`;
        const pickerItemsSelector = `${uniqueSelector} .ql-picker-item`;
        document.querySelector(pickerSelector).innerHTML = label + arrowSvg;
        const select = document.querySelector(`select${uniqueSelector}`);
        select.addEventListener('change', () => {
          const picker = document.querySelector(pickerSelector);
          picker.innerHTML = picker.dataset.label + arrowSvg;
        });

        const placeholderPickerItems = Array.prototype.slice.call(
          document.querySelectorAll(pickerItemsSelector),
        );
        placeholderPickerItems.forEach((item) => {
          if (item.dataset.value !== undefined) {
            item.textContent = item.dataset.label;
          } else {
            item.textContent = label;
          }
        });
      },
      fontFormatFromEditor(editor) {
        const Parchment = Quill.import('parchment');
        const fontsSet = new Set();
        const { index, length } = editor.selection.savedRange;
        let leaves = [];
        if (length === 0) {
          editor.scroll.path(index).forEach((path) => {
            const _path = _slicedToArray(path, 1);
            const blot = _path[0];

            if (blot instanceof Parchment.Leaf) {
              leaves.push(blot);
            }
          });
        } else {
          leaves = editor.scroll.descendants(Parchment.Leaf, index, length);
        }
        for (let i = 0; i < leaves.length; i++) {
          const classList = _get(leaves[i], 'parent.attributes.domNode.classList');
          for (let j = 0; j < classList.length; j++) {
            if (classList[j].startsWith('ql-font')) {
              fontsSet.add(classList[j].replace('ql-font-', ''));
            }
          }
        }
        return fontsSet;
      },
    },
  };
</script>
